<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="login">
		<div class="login-form">
			<div class="login-form-top">
				<img class="login-form-logo" src="@/assets/images/pc/user/logo.png" alt="">
				<img class="login-form-title" src="@/assets/images/pc/user/name.png" alt="">
			</div>
			<div class="login-form-middle">
				<div class="login-form-item">
					<img src="@/assets/images/pc/user/icon1.png" alt="">
					<input v-model="loginPc.account" type="text" placeholder="请输入账号">
				</div>
				<div class="login-form-item">
					<img src="@/assets/images/pc/user/icon3.png" alt="">
					<input v-model="loginPc.password" :type="isShowPwd?'text':'password'" placeholder="请输入密码">
					<img @click="showPwd" class="login-form-eye" :src="isShowPwd?openEye:closeEye" alt="">
				</div>
			</div>
			<div @click="submit" class="login-form-bottom login-form-active">
				立即登录
			</div>
			<div @click="handleLogin" class="login-form-bottom-green">
				<div class="img-box">
					<div class="img">
						<img src="@/assets/images/pc/user/school-icon.png" alt="">
					</div>
					<div>统一身份认证登录</div>
				</div>
				
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapActions
	} from 'vuex';
	import storage from 'store';
	import {
		getConfigCode
	} from "@/api/mobile/user";
	export default {
		data() {
			return {
				isShowPwd: false,
				openEye: require("@/assets/images/pc/user/open-eye.png"),
				closeEye: require("@/assets/images/pc/user/close-eye.png"),
				identityPc: "",
				//测试
				// plat:2是pc,1是移动
				// loginPc: {
				// 	account: "",
				// 	password: "",
				// 	plat:2,
				// 	code:'7777777'
				// },
				loginPc: {
					account: "",
					password: "",
					plat:2,
				}
			}
		},
		mounted() {
			this.identityPc = this.$route.query.identityPc
		},
		methods: {
			...mapActions(['pcLogin', 'pcUserInfo']),
			showPwd() {
				this.isShowPwd = !this.isShowPwd
			},
			submit() {
				let params = JSON.parse(JSON.stringify(this.loginPc))
				if (!params.account) {
					this.$message({
						message: '请输入账号~',
						type: 'error',
					});
					return
				}
				if (!params.password) {
					this.$message({
						message: '请输入密码~',
						type: 'error',
					});
					return
				}
				if(this.identityPc == 'T') {
					this.$set(params, "userType", "COLLEGE_MANAGER")
				} else if (this.identityPc == 'C') {
					this.$set(params, "userType", "SCHOOL_MANAGER")
				}
				console.log("pc端登录信息", params)
				this.pcLogin(params).then(async (res) => {
					if (res.code == 0) {
						await this.pcUserInfo()
						let userInfo = storage.get("accessInfo")
						let typeMap = {
							"STUDENT": "学生",
							"SCHOOL_MANAGER": "干部",
							"COLLEGE_MANAGER": "学院老师",
							"SCHOOL_TEACH": "学校老师",
						}
						storage.set("identityPc", typeMap[userInfo.userType])
						// if (userInfo.userType == 'STUDENT') {
						// 	this.$router.push({
						// 		path: `/mb/activity`
						// 	})
						// } else if (userInfo.userType == 'SCHOOL_TEACH') {
						// 	this.$router.push({
						// 		path: `/mb/manhour`
						// 	})
						// } else {
						// 	this.$router.push({
						// 		path: `/pc/activity`
						// 	})
						// }
						this.$router.push({
							path: `/pc/activity`
						})
					}
				})
			},
			handleLogin(){
				getConfigCode().then(res=>{
					if(res.code == 0){
						var pathUrl= "https://www.57qingong.zjut.edu.cn/#/pc/activity/index"
						const uri = encodeURIComponent(pathUrl)
						const REDIRECT_URI =res.data.baseUrl+'/cas/oauth2.0/authorize?response_type=code&client_id='+res.data.appKey+'&redirect_uri='+uri
						location.href = REDIRECT_URI
					}
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.login {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.login-form {
			display: flex;
			flex-direction: column;

			.login-form-top {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.login-form-logo {
					width: 68px;
					height: 68px;
					margin-bottom: 14px;
				}

				.login-form-title {
					width: 80px;
					height: 27px;
					margin-bottom: 12px;
				}
			}

			.login-form-middle {
				display: flex;
				flex-direction: column;

				.login-form-item {
					width: 311px;
					height: 40px;
					display: flex;
					align-items: center;
					background: #F7F8F9;
					border-radius: 4px;
					padding: 0 16px;
					box-sizing: border-box;
					margin-top: 16px;

					img {
						width: 16px;
						height: 16px;
						display: flex;
						align-items: center;
						margin-right: 8px;
					}

					input {
						flex: 1;
						border: 0;
						outline: none;
						background: #F7F8F9;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0px;
						color: #303133;
					}

					.login-form-eye {
						width: 16px;
						height: 16px;
						cursor: pointer;
					}
				}
			}

			.login-form-bottom {
				width: 311px;
				line-height: 40px;
				background: rgba(67, 146, 250, 0.4);
				border-radius: 4px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 24px;
				cursor: pointer;
			}
			.img-box{
				display: flex;
				align-items: center;
			}
			.login-form-bottom-green {
				width: 311px;
				// line-height: 40px;
				padding:10px 0;
				background: #5AB55A;
				border-radius: 4px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 24px;
				cursor: pointer;
				.img{
					width:20px;
					height:20px;
					margin-right:10px;
					img{
						width:20px;
						height:20px;
					}
				}
			}
			.login-form-active {
				background-color: rgba(67, 146, 250, 1);
			}
		}
	}
</style>
